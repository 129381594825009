import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { verifyJson } from "utils/validateAemJson";
import { globalPaths } from "@RHCommerceDev/aem/globalModels";
import { globalModels } from "@RHCommerceDev/aem/globalModels";
import { getAemEnv } from "@RHCommerceDev/aem/getAemEnv";

export const processModelResponse = data => {
  const escapeXml = unsafe => {
    return unsafe.replace(/(&lt;|&gt;|&amp;|&apos;|&quot;)/g, function (c) {
      switch (c) {
        case "&lt;":
          return "<";
        case "&gt;":
          return ">";
        case "&amp;":
          return "&";
        case "&apos;":
          return "'";
        case "&quot;":
          return '"';
      }
    });
  };
  let pageContent = verifyJson(data, "getAemModel")
    ? JSON.parse(data.getAemModel.model)
    : {};
  Object.keys(data ?? {}).forEach(
    key => {
      if (verifyJson(data, key) && pageContent) {
        pageContent[key] = JSON.parse(data[key]?.model || "");
      }
    },
    { pageContent, data }
  );
  const recursion = obj => {
    for (let i in obj) {
      if (typeof obj[i] == "object") {
        recursion(obj[i]);
      } else {
        typeof obj[i] === "string" && escapeXml(obj[i]);
      }
    }
  };
  recursion(pageContent);
  return pageContent;
};

export const globalQueryVariables = modelPath => {
  const aemEnv = getAemEnv();
  return {
    aemModelInput: {
      modelPath
    },
    ...Object.fromEntries(
      Object.entries(globalModels).map(([key, value]) => {
        return [
          key,
          {
            modelPath: globalPaths[key],
            aemEnv
          }
        ];
      })
    )
  };
};

export const queryGetAemModelGlobal = gql`
  query GetAemModel(
    $aemModelInput: AemModelInput!
    ${Object.keys(globalModels || {}).map(key => {
      return `$${key}: AemModelInput!`;
    })}
  ) {
    ${Object.keys(globalModels || {}).map(key => {
      return `${key}: getAemModel(aemModelInput: $${key}) {
        model
      }`;
    })}

    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;

export const globalAemModel = variables => {
  const queryGetAemModelGlobal = gql`
  query GetAemModel(
    $aemModelInput: AemModelInput!
    ${Object.keys(variables?.globalPaths || {}).map(key => {
      return `$${key}: AemModelInput!`;
    })}
  ) {
    ${Object.keys(variables?.globalPaths || {}).map(key => {
      return `${key}: getAemModel(aemModelInput: $${key}) {
        model
      }`;
    })}

    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;

  return useQuery(queryGetAemModelGlobal, {
    variables: {
      aemModelInput: {
        modelPath: variables.modelPath.replace(/\/\/+/g, "/")
      },
      ...Object.fromEntries(
        Object.entries(variables?.globalPaths).map(
          ([key, value]: [string, string]) => {
            return [key, { modelPath: value?.replace(/\/\/+/g, "/") }];
          }
        )
      )
    },
    client: variables.graphqlClient,
    skip: variables?.skip || false
  });
};

export const aemModel = variables => {
  const aemEnv = getAemEnv();
  return useQuery(queryGetAemModelGlobal, {
    variables: {
      aemModelInput: {
        modelPath: variables.modelPath.replace(/\/\/+/g, "/"),
        aemEnv
      }
    },
    client: variables.graphqlClient
  });
};

export const queryGetAemModel = gql`
  query GetAemModel($aemModelInput: AemModelInput!) {
    getAemModel(aemModelInput: $aemModelInput) {
      model
    }
  }
`;
