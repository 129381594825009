// import { MembershipInfo } from "graphql-client/fragments/membership-info";
import gql from "graphql-tag";

const MembershipInfo = gql`
  fragment MembershipInfo on MembershipInfoType {
    userHasActiveMembership
    userHasCancelledMembership
    userHasExpiredMembership
    currencyCode
    membershipId
  }
`;

export const deleteSessionMutation = gql`
  mutation DeleteSession {
    deleteSession {
      sessionId
      rhuid
    }
  }
`;

export const queryGetUserForSession = gql`
  query GetUserForSession(
    $useCartBroker: Boolean!
    $country: String
    $postalCode: String
    $createCart: Boolean
    $doNotMutateCart: Boolean
    $queryTimeStamp: String
  ) {
    getUserForSession(
      useCartBroker: $useCartBroker
      country: $country
      postalCode: $postalCode
      createCart: $createCart
      doNotMutateCart: $doNotMutateCart
      queryTimeStamp: $queryTimeStamp
    ) {
      sessionId
      rhuid
      location
      currentCartId
      validAssociate
      location
      rhUser {
        id
        firstName
        lastName
        email
        userType
        gender
        globalSfContactId
        sfContactId
        sfAccountType
        company
        shopperRef
        createdDate
        locale
        defaultShippingAddressId
        postalCode
        akamaiCountryCode
        newUser
      }
      membershipInfo {
        ...MembershipInfo
      }
      cookiePreferences {
        userSavedCookiePreferences
        preferencesFunctionalityCookie
        thirdPartyAnalyticsCookie
        strictlyNecessaryCookie
        analyticsCookie
        userAcceptedAllCookies
        cookieRules
      }
    }
  }
  ${MembershipInfo}
`;

export const queryGetCartIdForSession = gql`
  query getCartIdForSession {
    getCartIdForSession {
      cartId
      userId
      sessionId
      error
    }
  }
`;

export const updateUserForSessionMutation = gql`
  mutation UpdateUserForSession(
    $userInput: UserInput!
    $currentCartId: String!
    $useCartBroker: Boolean!
  ) {
    updateUserForSession(
      userInput: $userInput
      currentCartId: $currentCartId
      useCartBroker: $useCartBroker
    ) {
      sessionId
      rhuid
      currentCartId
      validAssociate
      rhUser {
        id
        firstName
        lastName
        email
        userType
        gender
        globalSfContactId
        sfContactId
        sfAccountType
        company
        shopperRef
        createdDate
        locale
        defaultShippingAddressId
        akamaiCountryCode
        newUser
      }
      membershipInfo {
        ...MembershipInfo
      }
      cookiePreferences {
        userSavedCookiePreferences
        preferencesFunctionalityCookie
        thirdPartyAnalyticsCookie
        strictlyNecessaryCookie
        analyticsCookie
        userAcceptedAllCookies
        cookieRules
      }
    }
  }
  ${MembershipInfo}
`;
