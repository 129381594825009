import gql from "graphql-tag";
import { PriceRangeDisplay } from "graphql-client-product/fragments/price-range-display";
import { LineItem } from "graphql-client-product/fragments/product-line-item";
import { BaseProductFields } from "graphql-client-product/fragments/base-product-fields";
import {
  PersonalizeInfo,
  Swatch
} from "graphql-client-product/fragments/product";

export const ProductFields = gql`
  fragment ProductFields on Product {
    __typename
    ...BaseProductFields
    personalizeInfo {
      ...PersonalizeInfo
    }
    # relatedProducts {
    #   __typename
    #   ...BaseProductFields
    #   personalizeInfo {
    #     ...PersonalizeInfo
    #   }
    #   productLineItem(filter: $filter) {
    #     ...LineItem
    #   }
    # }
    productLineItem(filter: $filter) {
      ...LineItem
    }
    category
    spoReturnMessageOverride
    spoReturnMessageOverrideKey
    collectionName
    productDescription
    designerName
    swatchData(splitDisplay: true, filter: $filter, categoryId: $categoryId) {
      productId
      adapter
      splitDisplay
      faceoutSwatchId
      swatchGroups {
        swatchGroupName
        groupMaterial
        swatchGroupIndex
        sortPriority
        stockedSwatches {
          ...Swatch
        }
        customSwatches {
          ...Swatch
        }
      }
      finishSwatchGroups {
        swatchGroupName
        groupMaterial
        swatchGroupIndex
        sortPriority
        stockedSwatches {
          ...Swatch
        }
      }
    }
    customProductType
    swatchesToBuy {
      atgSkuId
      swatchId
      productId
      fullSkuId
    }
    carouselProdctDetails {
      imageUrl
      displayName
      productId
      sale
    }
    customProduct
    conciergeOnlyForNewPDP
    productOptionGroupBean {
      title
      optionsList
    }
    swatchBean {
      swatchId
      primaryOptionId
    }
    fullSkuId
    ctaLinks {
      layerType
      label
      leftCoordinate
      topCoordinate
      flyoutDirection
      htmlText
      shortText
      link
      modal
      path
    }
  }
  ${BaseProductFields}
  ${PriceRangeDisplay}
  ${PersonalizeInfo}
  ${Swatch}
  ${LineItem}
`;
